<template class="flex-grow-1">
  <div style="height: 100%">
    <LoadingPopup :loading="downloadLoading"></LoadingPopup>

    <b-sidebar id="sidebar-1" title="Sidebar" left no-header visible sidebar-class="left-info-panel" width="500px"
               z-index="1001">
      <b-container class="pt-3" style="background: #FEFEFE;">
        <div class="d-flex flex-row justify-content-between">
          <button class="btn-return" @click="$router.push('/')">
            <div class="info-text">
              <img src="../assets/icons/left_arrow.png" style="padding: 4px">
              <span class="ml-2 info-text" style="font-weight: bold; padding-left: 15px"> Выйти из режима создания территории</span>
            </div>
          </button>
          <img src="../assets/icons/logo_itmo.png" style="width: 79px; height: 30px">
        </div>

        <h1 class="very-large-title-text" style="margin-top: 20px; margin-bottom: 20px">Новая генерация</h1>

        <div v-if="loading" class="error-panel"
             style="background-color: yellow; color: black; width: 470px; height: 100px; display: flex; align-items: center; justify-content: center;">
          <span>Загрузка...</span>
        </div>

        <div v-else-if="projectNotFound" class="error-panel"
             style="background-color: red; color: white; width: 470px; height: 100px; display: flex; align-items: center; justify-content: center;">
          <span>Проект с таким ID не найден</span>
        </div>

        <div v-else-if="projectNotReady" class="error-panel"
             style="background-color: yellow; color: black; width: 470px; height: 100px; display: flex; align-items: center; justify-content: center;">
          <span>Генерация еще не завершена</span>
        </div>

        <template v-else>
          <b-dropdown text="Показать варианты генерации" variant="none" class="result" no-caret
                      style="position: relative; width: 450px; z-index: 1;">
            <div class="menu">
              <b-dropdown-item v-for="result in allResults" :key="result" @click="showResult(result)">
                {{ 'Результат ' + result }}
              </b-dropdown-item>
            </div>
          </b-dropdown>

          <div v-if="showObjectList">

            <b-tabs>
              <!-- Вкладка ТЭП -->
              <b-tab>
                <template v-slot:title>
                  <span class="result-text">ТЭП</span>
                </template>
                <b-container v-if="messages && !isProjectFailed()" id="sidebar-1" title="Sidebar" right no-header
                             visible width="500px" z-index="1000" style="margin-top: 10px">
                  <b-row>
                    <b-col lg="6">
                      <div v-for="(value, key) in tep" :key="key">
                        <div class="row">
                          <div class="col-8">
                            <div class="result-panel flex-column"
                                 style="margin-bottom: 10px; background: #F3F7F3; width: 450px; height: 100px; align-items: start">
                              <span class="name-span info-text">{{ displayKey(key) }}:</span>
                              <span class="result-parameters">
                      <div>{{ displayValue(key, value) }}</div>
                    </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-col>
                  </b-row>
                </b-container>
                <div v-if="messages && isProjectFailed()" class="error-panel"
                     style="background-color: red; color: white; width: 450px; height: 100px; display: flex; align-items: center; justify-content: center;">
                  <span>Генерация проекта закончилась с ошибкой</span>
                </div>
              </b-tab>

              <!-- Вкладка Сообщения -->
              <b-tab>
                <template v-slot:title>
                  <span class="result-text">{{ messagesTitle }}</span>
                </template>
                <div class="info-text">
                  <b-container style="margin-top: 10px">
                    <b-row>
                      <b-col lg="6">
                        <div v-for="message in this.messages" :key="message.timestamp">
                          <div class="result-panel flex-column" :style="getPanelStyle(message)"
                               style="margin-bottom: 10px; width: 450px; min-height: 100px; align-items: start;">
                            <span class="info-text">Уровень: {{ message.level }}</span>
                            <div>Сообщение: {{ message.message }}</div>
                            <div>Время: {{ new Date(message.timestamp).toLocaleString() }}</div>
                          </div>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </b-tab>

              <!-- Параметры генерации -->
              <b-tab>
                <template v-slot:title>
                  <span class="result-text">Параметры генерации</span>
                </template>
                <div class="info-text">
                  <b-container style="margin-top: 10px">
                    <b-row>
                      <b-col lg="6">
                        <div class="result-panel flex-column"
                             style="background: #F3F7F3; margin-bottom: 10px; width: 450px; min-height: 100px; align-items: start;">
                          <span class="info-text"><b>Алгоритм:&nbsp;</b> {{ algorithmLabel }}</span>
                          <span class="info-text"><b>Расчет инсоляции:&nbsp;</b> {{
                              resultProject.requestDTO.calculateInsolation ? 'Да' : 'Нет'
                            }}</span>
                          <span v-if="resultProject.requestDTO.maxLevels" class="info-text"><b>Максимальное количество этажей:&nbsp;</b> {{
                              resultProject.requestDTO.maxLevels
                            }}</span>
                          <span v-if="resultProject.requestDTO.minLevels" class="info-text"><b>Минимальное количество этажей:&nbsp;</b> {{
                              resultProject.requestDTO.minLevels
                            }}</span>
                          <span class="info-text"><b>Проверка окружения:&nbsp;</b>
                {{ resultProject.requestDTO.surroundings === 'NONE' ? 'Не проверялась' : 'Проверялась' }} </span>
                          <span v-if="resultProject.requestDTO.maxFSI !== undefined" class="info-text">
              <b>Максимальное значение FSI:&nbsp;</b> {{ resultProject.requestDTO.maxFSI }}
            </span>
                          <span v-if="resultProject.requestDTO.maxGSI !== undefined" class="info-text">
              <b>Максимальное значение  GSI:&nbsp;</b> {{ resultProject.requestDTO.maxGSI }}
            </span>
                          <span v-if="resultProject.requestDTO.maxSectionsInBuilding !== undefined" class="info-text">
              <b>Макс. количество секций:&nbsp;</b> {{ resultProject.requestDTO.maxSectionsInBuilding }}
            </span>
                          <span v-if="resultProject.requestDTO.maxSectionLength !== undefined" class="info-text">
              <b>Макс. длина секции:&nbsp;</b> {{ resultProject.requestDTO.maxSectionLength }}
            </span>
                          <span v-if="resultProject.requestDTO.variantsCount !== undefined" class="info-text">
              <b>Количество ОПР:&nbsp;</b> {{ resultProject.requestDTO.variantsCount }}
            </span>
                          <span v-if="resultProject.requestDTO.buildingShapes && resultProject.requestDTO.buildingShapes.length" class="info-text">
            <b>Выбранные формы зданий:</b>
            <div class="building-shapes-grid">
              <div v-for="(shape, index) in resultProject.requestDTO.buildingShapes" :key="index" class="building-shape-item">
                {{ shapeNames[shape] || shape }}
              </div>
            </div>
          </span>
                          <span class="info-text"><b>Расчет парковки:&nbsp;</b>
                {{ resultProject.requestDTO.calculateParking === 'NONE' ? 'Не проверялась' : 'Проверялась' }} </span>
                          <span v-if="resultProject.requestDTO.calculateParking !== undefined" class="info-text">
              <b>Коэф. полезн. площади МКД:&nbsp;</b> {{ resultProject.requestDTO.targetAreaEfficiency }}
            </span>
                        </div>
                      </b-col>
                    </b-row>
                  </b-container>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </template>
      </b-container>

      <b-dropdown v-if="id !== null && messages && !isProjectFailed()"
                  id="dropdown-1"
                  text="Загрузить результаты генерации в формате DXF"
                  variant="none"
                  class="result"
                  no-caret dropup
                  style="margin-top: 10px; position: relative;  z-index: 1">
        <template #button-content>
          <div class="d-flex flex-row align-content-center justify-content-center">
            <img width="18" height="18" src="@/assets/icons/download.png">
            <div class="result-text">Загрузить результаты генерации в формате DXF</div>
          </div>
        </template>
        <div class="menu">
          <b-dropdown-item v-for="floor in floors" :key="floor" @click="downloadProjectDXF(floor)">
            {{ 'DXF файл с результатом генерации ' + floor + ' этажа' }}
          </b-dropdown-item>
          <b-dropdown-item v-if="hasParking" @click="downloadProjectDXF(-1)">DXF файл с результатом генерации паркинга
          </b-dropdown-item>
          <b-dropdown-item @click="downloadProjectZip">ZIP архив с файлами этажей</b-dropdown-item>
        </div>
      </b-dropdown>

      <b-dropdown v-if="id !== null && messages && !isProjectFailed()"
                  id="dropdown-2"
                  text="Загрузить результаты генерации в формате XLSX"
                  variant="none"
                  class="result"
                  no-caret dropup
                  style="margin-top: 10px; position: relative;  z-index: 2">
        <template #button-content>
          <div class="d-flex flex-row align-content-center justify-content-center">
            <img width="18" height="18" src="@/assets/icons/download.png">
            <div class="result-text">Загрузить результаты генерации в формате XLSX</div>
          </div>
        </template>
        <div class="menu">
          <b-dropdown-item v-for="floor in floors" :key="floor" @click="downloadProjectXLSX(floor)">
            {{ 'XLSX файл с результатом генерации ' + floor + ' этажа' }}
          </b-dropdown-item>
          <b-dropdown-item @click="downloadXLSXTotalTep()">Общий XLSX файл с ТЭП генерации</b-dropdown-item>
        </div>
      </b-dropdown>

      <b-dropdown v-if="id !== null && messages && !isProjectFailed()"
                  text="Показать план этажа"
                  variant="none" class="result"
                  no-caret dropup
                  style="position: relative;  z-index: 3">
        <div class="menu">
          <b-dropdown-item v-for="floor in floors" :key="floor" @click="showFloorPlan(floor)">
            {{ 'Этаж ' + floor }}
          </b-dropdown-item>
          <b-dropdown-item v-if="hasParking" @click="showFloorPlan(-1)">План паркинга</b-dropdown-item>
          <b-dropdown-item @click="showResult(variantId + 1)">Общий план здания</b-dropdown-item>
        </div>
      </b-dropdown>
      <button class="again-button" @click="generateAgain">Сгенерировать заново</button>
    </b-sidebar>

    <div id="app">
      <div id="map"></div>
    </div>

  </div>
</template>

<script>
import {latLng} from "leaflet";
import maplibregl from 'maplibre-gl';
import MapAPI from "@/mixins/MapAPI";
import LoadingPopup from "@/components/LoadingPopup";
import * as turf from "@turf/turf";


export default {
  name: "Result",
  components: {
    LoadingPopup
  },
  mixins: [
    MapAPI
  ],
  data() {
    return {
      id: null,
      areaPolygon: null,
      downloadLoading: false,
      areaBounds: null,
      resultProject: null,
      resultObjects: null,
      resultZones: null,
      showObjectList: true,
      floorValue: 1,
      floorGeoJSON: null,
      showMapModal: false,
      hasParking: false,
      tep: {},
      results: {},
      resultVariants: 1,
      messages: {},
      surroundings: [],
      insolationPoints: [],
      isInsolated: false,
      projectNotFound: false,
      projectNotReady: false,
      loading: true,
      variantId: 0,
      map: null,
      expandedItem: null,
      mapboxToken: 'pk.eyJ1IjoiZXNtaXJub3YiLCJhIjoiY2t1d2luYW0wMGdjdDJvbXJsMGVyc2lmdyJ9.wtMpaXCT-IvPO3eTDglP0Q',
      zoom: 15,
      fillColor: 'lightcoral',
      center: latLng(55.763115, 37.622008),
      centerCoordinates: latLng(55.763115, 37.622008),
      url: 'https://{s}.basemaps.cartocdn.com/rastertiles/light_all/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      aptRoomsToColorMap: {
        "0": "#FF6450",
        "1": "#D459E8",
        "2": "#BF0060",
        "3": "#FDAB01",
        "4": "#006599",
        "5": "#31e1b0",
        "6": "#547bf2"
      },
      shapeNames: {
        TOWER: "Башня",
        LINEAR: "Линейное",
        L: "Г-образное",
        U: "П-образное"
      }
    }
  },

  mounted() {

    this.loadFromId()

    this.id = this.$route.params.resultId;

    self.map = new maplibregl.Map({
      style: `https://api.mapbox.com/styles/v1/mapbox/streets-v11?access_token=${this.mapboxToken}`,
      center: [37.622008, 55.763115],
      zoom: 15.5,
      pitch: 45,
      bearing: -17.6,
      container: 'map',
      antialias: true
    });

    self.map.on('load', () => {
      self.map.addSource('osm-tiles', {
        type: 'raster',
        tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
        tileSize: 256,
      });

      self.map.addLayer({
        id: 'osm-layer',
        type: 'raster',
        source: 'osm-tiles',
      });
    });
  },

  computed: {
    floors() {
      return Array.from({length: this.floorValue}, (_, i) => i + 1);
    },
    messagesTitle() {
      return `Сообщения (${this.messages.length})`;
    },
    algorithmLabel() {
      if (this.resultProject?.requestDTO?.algorithm === 'SECTION') {
        return 'Генерация из типовых секций';
      } else if (this.resultProject?.requestDTO?.algorithm === 'BUILDING') {
        return 'Генерация из типовых МКД';
      }
      return 'Неизвестный алгоритм';
    },
    allResults() {
      return Array.from({length: this.resultVariants}, (_, i) => i + 1);
    }
  },

  methods: {

    generateAgain() {
      this.$router.push({
        name: 'editor',
        params: {
          projectGeometry: this.areaPolygon
        }
      });
    },

    displayKey(key) {
      const keyMap = {
        parcelArea: "Площадь участка, м2",
        buildingArea: "Площадь застройки, м2",
        gsi: "Коэффициент застройки (GSI) ",
        fsi: "Коэффициент плотности застройки (FSI)",
        requiredAptsSatisfied: "Количество расселяемых квартир",
        requiredAptsSatisfiedShare: "Доля расселяемых квартир от заданного",
        extraAptsSatisfied: "Количество дополнительных квартир",
        extraAptsSatisfiedShare: "Доля дополнительных квартир от заданного",
        totalLivingArea: "Общая жилая площадь, м2",
        totalAptArea: "Общая площадь квартир, м2",
        totalAptAreaWithoutBalcony: "Общая площадь квартир без летних помещений, м2",
        parkingPlaces: "Количество машиномест в подземном паркинге",
        areaEfficiency: "Коэффициент полезной площади МКД",
        relocationRatio: "Коэффициент переселения",
        totalAreaWithoutElevators: "Общая наземная S здания"

      };
      return keyMap[key] || key;
    },

    hasErrorMessage(messages) {
      for (let i = 0; i < messages.length; i++) {
        if (messages[i].level === 'ERROR') {
          return true; // Если нашли сообщение с уровнем ERROR, возвращаем true и блокируем отображение ТЭП и кнопки
        }
      }
    },

    isProjectFailed() {
      if (!this.resultProject) {
        return false
      }
      if (this.resultProject.state === "ERROR") {
        return true
      }
      if (this.resultProject.results && this.resultProject.results.length === 0 && this.hasErrorMessage(this.resultProject.messages)) {
        return true
      }
      return false;
    },

    getPanelStyle(message) {
      //Получаем цвет панели сообщений в зависимости от его уровня
      let backgroundColor = '#F3F7F3';
      if (message.level === 'INFO') {
        backgroundColor = '#C8E6C9';
      } else if (message.level === 'WARNING') {
        backgroundColor = '#FFF9C4';
      } else if (message.level === 'ERROR') {
        backgroundColor = '#FFCDD2';
      }
      return {backgroundColor: backgroundColor};
    },

    displayValue(key, value) {
      if (!value || value <= 0) {
        return "не задано"
      }
      if (key === "requiredAptsSatisfied" || key === "extraAptsSatisfied") {
        return value.toFixed(0)
      }
      if (key.includes("Share")) {
        return (value * 100).toFixed(0) + "%"
      }
      if (key === "parkingPlaces") {
        return value.toFixed(0)
      }
      return value.toFixed(2)
    },

    async downloadProjectDXF(floor) {
      this.downloadLoading = true;
      this.downloadDXF(this.id, this.variantId, floor).then(rz => {
        this.downloadLoading = false;
        this.openSaveFileDialog(rz, `dxfFile_${this.id}_${floor}.dxf`)
      });
    },

    async downloadProjectXLSX(floor) {
      this.downloadLoading = true;
      this.downloadXLSX(this.id, this.variantId, floor).then(rz => {
        this.downloadLoading = false;
        this.openSaveFileDialog(rz, `xlsxFile_${this.id}_${floor}.xlsx`)
      });
    },

    async downloadXLSXTotalTep() {
      this.downloadLoading = true;
      this.downloadXLSXTotal(this.id, this.variantId).then(rz => {
        this.downloadLoading = false;
        this.openSaveFileDialog(rz, `xlsxFile_${this.id}.xlsx`)
      });
    },

    async downloadProjectZip() {
      this.downloadLoading = true;
      this.downloadZip(this.id, this.variantId).then(rz => {
        this.downloadLoading = false;
        this.openSaveFileDialog(rz, `zipFile_${this.id}.zip`)
      });
    },

    async showFloorPlan(floor) {
      try {
        this.floorGeoJSON = await this.getGenerationFloorGeoJSON(floor, this.variantId, this.id);
        this.showMapModal = true;
        this.initializeMap();
      } catch (error) {
        console.error("Ошибка получения GeoJSON:", error);
      }
    },

    initializeMap() {
      self.map = new maplibregl.Map({
        style: `https://api.mapbox.com/styles/v1/mapbox/streets-v11?access_token=${this.mapboxToken}`,
        center: this.centerCoordinates,
        zoom: 18,
        pitch: 45,
        bearing: -17.6,
        container: 'map',
        antialias: true
      });

      self.map.on('load', () => {
        self.map.addSource('osm-tiles', {
          type: 'raster',
          tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
          tileSize: 256,
        });

        self.map.addLayer({
          id: 'osm-layer',
          type: 'raster',
          source: 'osm-tiles',
        });

        self.map.on('click', 'apartments', function (e) {

          if (e.features.length > 0) {
            const feature = e.features[0];
            console.log(feature)
            let content = `<h6>Тип объекта: ${feature.properties.type}</h6>`;

            if (feature.properties.roomType !== undefined) {
              content += `<h6>Тип (ID) комнты: ${feature.properties.roomType}</h6>`;
            }

            if (feature.properties.apartmentType !== undefined) {
              content += `<h6>Тип (ID) квартиры: ${feature.properties.apartmentType}</h6>`;
            }

            if (feature.properties.area !== undefined) {
              content += `<h6>Площадь комнаты: ${feature.properties.area.toFixed(2)} кв.м</h6>`;
            }

            if (feature.properties.livingArea !== undefined) {
              content += `<h6>Жилая площадь: ${feature.properties.livingArea.toFixed(2)} кв.м</h6>`;
            }

            if (feature.properties.totalArea !== undefined) {
              content += `<h6>Общая площадь: ${feature.properties.totalArea.toFixed(2)} кв.м</h6>`;
            }

            if (feature.properties.roomType !== undefined && e.features.length > 1) {
              content += `<h6>Входит в квартиру:</h6>`;
              content += `<h6>Тип квартиры: ${e.features[1].properties.apartmentType}</h6>`;
              content += `<h6>Площадь: ${e.features[1].properties.totalArea.toFixed(2)}</h6>`;
              content += `<h6>Жилая площадь: ${e.features[1].properties.livingArea.toFixed(2)}</h6>`;
            }

            new maplibregl.Popup()
                .setLngLat(e.lngLat)
                .setHTML(content)
                .addTo(self.map);
          }
        });

        this.floorGeoJSON.data.features.forEach(feature => {
          if (feature.properties.type === 'apartment') {
            feature.properties.apartmentColor = this.getColorByApartmentType(feature.properties.rooms);
          } else if (feature.properties.subtype === "service") {
            feature.properties.apartmentColor = this.getServiceColorByType(feature.properties.type)
          }
        });

        self.map.addSource('floors', {
          'type': 'geojson',
          'data': this.floorGeoJSON.data,
        });

        self.map.addLayer({
          'id': 'apartments',
          'type': 'fill',
          'source': 'floors',
          'layout': {},
          'paint': {
            'fill-color': [
              'match',
              ['get', 'type'],
              'corridor', 'gray', // Если 'corridor', используем серый цвет
              'ladder', 'gray', // Если 'ladder', используем темно-синий цвет
              'elevator', 'gray',
              'balcony', 'rgb(255,128,0)',
              'apartment', ['get', 'apartmentColor'], // Если 'apartment', используем цвет из хэша значения типа квартиры
              'security', ['get', 'apartmentColor'],
              'parking', ['get', 'apartmentColor'],
              'toilet', ['get', 'apartmentColor'],
              'storage', ['get', 'apartmentColor'],
              'commercial', ['get', 'apartmentColor'],
              'rgba(196,230,173,0.1)' // Цвет по умолчанию, если не соответствует ни одному из условий
            ],
            'fill-outline-color': 'black',
            'fill-opacity': 0.8,
          },
        });


        // дополнительно покрасим балконы - они за пределами здания и потому прозрачны
        self.map.addLayer({
          'id': 'balconies',
          'type': 'fill',
          'source': 'floors',
          'layout': {},
          'paint': {
            'fill-color': 'rgb(255,128,0)',
            'fill-opacity': 1.0
          },
          'filter': ['==', ['get', 'roomType'], 'BALCONY'],
        });

        // Граница комнат задана пунктирной линией
        self.map.addLayer({
          'id': 'room-borders',
          'type': 'line',
          'source': 'floors',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': 'black',
            'line-width': 0.8
          },
          'filter': ['==', ['get', 'type'], 'room'],
        });


        // стены сервисных помещений не входящих в квартиры нарисуем потолще
        self.map.addLayer({
          'id': 'service-borders',
          'type': 'line',
          'source': 'floors',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': 'black',
            'line-width': 1.5
          },
          'filter': ['==', ['get', 'subtype'], 'service'],
        });

        // дополнительно жирно границы между квартирами
        self.map.addLayer({
          'id': 'apartment-borders',
          'type': 'line',
          'source': 'floors',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': 'black',
            'line-width': 3,
          },
          'filter': ['==', ['get', 'type'], 'apartment'],
        });

        //Внешная граница
        // Граница блоков (block) — пунктирная линия
        self.map.addLayer({
          'id': 'block-borders',
          'type': 'line',
          'source': 'floors',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': 'black',
            'line-width': 2,
            'line-dasharray': [4, 2]
          },
          'filter': ['==', ['get', 'type'], 'block'],
        });

        // Граница секций (section) — жирная линия
        self.map.addLayer({
          'id': 'section-borders',
          'type': 'line',
          'source': 'floors',
          'layout': {
            'line-cap': 'round',
            'line-join': 'round'
          },
          'paint': {
            'line-color': 'black',
            'line-width': 5
          },
          'filter': ['==', ['get', 'type'], 'section'],
        });
      });
    },

    getColorByApartmentType(roomCount) {
      if (roomCount > 6) {
        return "#eb0004"
      }
      let color = this.aptRoomsToColorMap['' + roomCount];
      return color
    },

    getServiceColorByType(type) {
      switch (type) {
        case "ladder":
        case "corridor":
          return "gray"
        case "security":
          return "#00c4ff"
        case "toilet":
          return "#0000ff"
        case "storage":
          return "#c06e42"
        case "commercial":
          return "#d7c252"
        case "parking":
          return "#ffdd00"
        default:
          return "#ffa000"
      }
    },

    openSaveFileDialog(axisoRespone, fileName) {
      const url = window.URL.createObjectURL(new Blob([axisoRespone.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
    },

    loadFromId() {
      this.id = this.$route.params.resultId;
      let self = this
      this.getGenerationResult(this.id, 'BLOCKS').then(rz => {
        if (rz.status === 200 && rz.data.code === 0) {
          if (rz.data.data.state === 'RUNNING') {
            this.loading = false
            this.projectNotReady = true
          }
          this.loading = false
          this.resultProject = rz.data.data
          let firstResult = rz.data.data.results[0];
          this.areaPolygon = firstResult.area
          if (firstResult.surroundings) {
            self.surroundings = firstResult.surroundings
          }

          this.results = rz.data.data.results
          this.resultVariants = rz.data.data.results.length

          this.messages = rz.data.data.messages

          this.showResult(1)
        } else if (rz.data.code === 104) {
          this.loading = false
          this.projectNotFound = true
        }
      })
    },

    showResult(result) {
      this.variantId = result - 1
      let self = this
      let generatedResult = this.results[this.variantId];
      this.hasParking = generatedResult.needsParking === true
      if (generatedResult.surroundings) {
        self.surroundings = generatedResult.surroundings
      }

      this.getGenerationFloorGeoJSON(this.floorValue - 1, this.variantId, this.id).then(geoJSONData => {
        this.floorGeoJSON = geoJSONData
      })

      //Создание объекта JSON для отрисовки участка генерации

      this.areaBounds = {
        type: "FeatureCollection",
        features: [{
          type: "Feature",
          geometry: generatedResult.area,
          properties: {
            type: "border"
          }
        }]
      }

      //Создание объекта JSON для отрисовки объекта генерации

      this.resultObjects = {
        type: "FeatureCollection",
        features: []
      }

      let blocks = generatedResult.blocks;

      this.floorValue = 0;
      for (let block of blocks) {
        if (block.floors) {
          //todo удалить позже
          this.floorValue = Math.max(block.floors, this.floorValue)
        } else {
          for (let section of block.sections) {
            this.floorValue = Math.max(this.floorValue, section.floors)
          }
        }
      }


      const floorHeight = 3.0
      // прверим что это новый проект, в котором у секций есть своя геометрия
      // todo: через некоторое время можно будет считать, что других проектов нет
      if (blocks && blocks.length > 0 && blocks[0].sections && blocks[0].sections.length > 0 && blocks[0].sections[0].geometry) {
        for (let block of blocks) {
          for (let section of block.sections) {
            this.resultObjects.features.push(
                {
                  type: "Feature",
                  geometry: section.geometry,
                  properties: {
                    type: "Polygon",
                    height: section.floors * floorHeight + 1 // +1 так как первый этаж на метр выше
                  }

                }
            )
          }
        }
      } else {
        // старые пректы без секций с разной этажностью
        this.resultObjects.features.push(
            {
              type: "Feature",
              geometry: generatedResult.buildingGeometry,
              properties: {
                type: "Polygon",
                height: this.floorValue * floorHeight + 1
              }
            }
        )
      }


      //Создание параметров генерации
      self.tep = generatedResult.economyParameters

      //Создание объектов для отрисовки инсоляции, если имеются
      if (generatedResult.insolationPoints) {
        this.insolationPoints = generatedResult.insolationPoints
      }

      //Определение центральной точки для перемещения камеры и высотры отрисовки здания
      self.resultZones = generatedResult.area
      self.center = turf.center(self.areaBounds.features[0].geometry);
      const center = turf.center(generatedResult.area);

      this.centerCoordinates = center.geometry.coordinates;

      self.map = new maplibregl.Map({
        style: `https://api.mapbox.com/styles/v1/mapbox/streets-v11?access_token=${this.mapboxToken}`,
        center: this.centerCoordinates,
        zoom: 15.5,
        pitch: 45,
        bearing: -17.6,
        container: 'map',
        antialias: true
      });

      self.map.on('load', () => {
        self.map.addSource('osm-tiles', {
          type: 'raster',
          tiles: ['https://a.tile.openstreetmap.org/{z}/{x}/{y}.png'],
          tileSize: 256,
        });

        self.map.addLayer({
          id: 'osm-layer',
          type: 'raster',
          source: 'osm-tiles',
        });

        //Следующий код задает источник и слой для подложки

        self.map.addSource('areaBoundsSource', {
          'type': 'geojson',
          'data': this.areaBounds,
        });


        self.map.addLayer({
          'id': 'areaBoundsLayer',
          'type': 'fill',
          'source': 'areaBoundsSource',
          'layout': {},
          'paint': {
            'fill-color': 'rgba(173, 216, 230, 0.8)',
            'fill-outline-color': 'blue',
            'fill-opacity': 0.8,
          },
        });

        //Следующий код задает источник и слой для объекта генерации

        self.map.addSource('generatedBuildingSource', {
          'type': 'geojson',
          'data': this.resultObjects,
        });

        self.map.addLayer({
          'id': 'generatedBuildingLayer',
          'type': 'fill-extrusion',
          'source': 'generatedBuildingSource',
          'layout': {},
          'paint': {
            'fill-extrusion-color': this.fillColor,
            'fill-extrusion-height': [
              'interpolate',
              ['linear'],
              ['zoom'],
              5,
              0,
              16,
              ['get', 'height']
            ],
            'fill-extrusion-base': 0,
            'fill-extrusion-opacity': 1.0,
          }
        });

        //Следующий код задает источник и слой для окружающий объектов

        if (self.surroundings != null) {
          self.surroundings.forEach((building, index) => {

            const surrounding = {
              type: "FeatureCollection",
              features: [{
                type: "Feature",
                geometry: building.geometry,
                properties: {
                  type: "Polygon"
                }
              }]
            }

            const layerId = 'surrounding-' + index;
            const levels = building.levels ? building.levels : 1
            const height = building.height ? building.height : levels * 3;
            self.map.addSource(layerId, {
              'type': 'geojson',
              'data': surrounding
            });


            self.map.addLayer({
              id: layerId + '-layer',
              type: 'fill-extrusion',
              source: layerId,
              layout: {},
              paint: {
                'fill-extrusion-color': 'lightgray',
                'fill-extrusion-height': [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  5,
                  0,
                  16,
                  height
                ],
                'fill-extrusion-base': 0,
                'fill-extrusion-opacity': 1.0,
              }
            });
          });
        }

        //Следующий код задает источник и слой для инсоляции

        if (this.insolationPoints != null) {
          const features = [];

          this.insolationPoints.forEach((insolation) => {
            const circleFeature = turf.circle(turf.point([insolation.x, insolation.y]), 0.5, {
              steps: 4,
              units: 'meters'
            });
            const fillColor = insolation.insolated ? '#00ff00' : '#ff0000';

            features.push({
              type: 'Feature',
              geometry: circleFeature.geometry,
              properties: {
                fillColor: fillColor,
                elevation: insolation.elevation
              }
            });
          });

          this.map.addSource('insolationBase', {
            type: 'geojson',
            data: {
              type: 'FeatureCollection',
              features: features
            }
          });

          this.map.addLayer({
            id: 'insolationLayer',
            type: 'fill-extrusion',
            source: 'insolationBase',
            paint: {
              'fill-extrusion-color': ['get', 'fillColor'],
              'fill-extrusion-height': ['+', ['get', 'elevation'], 1],
              'fill-extrusion-base': ['get', 'elevation'],
              'fill-extrusion-opacity': 1
            }
          });
        }
      });
    },

  }
}
</script>

<style scoped>
@import '../assets/styles/map.css';

.name-span {
  max-width: 350px;
  max-height: 50px;
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  direction: ltr;
  display: inline-block;
  vertical-align: top;
  cursor: pointer;
}

.name-span:hover {
  white-space: normal;
  max-height: none;
}

.result-text {

  font-family: Mulish;
  font-size: 15px;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0;
  text-align: center;
  margin-left: 5px;

}

.result {
  width: 480px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 12px 26px;
  gap: 10px;
  background: #fefefe;
  color: #2D2D2D;
  backdrop-filter: blur(3.5px);
  border-radius: 12px;
  font-family: Mulish;
  font-size: 16px;
  border: 1px solid lightgray;
  margin-bottom: 16px;
  font-weight: bold;
  letter-spacing: 0;
}

.menu {
  max-height: 170px;
  overflow: auto;
}

.again-button {
  width: 480px;
  height: 48px;
  justify-content: center;
  align-items: center;
  padding: 12px 26px;
  gap: 10px;
  background: #fefefe;
  color: #2D2D2D;
  backdrop-filter: blur(3.5px);
  border-radius: 12px;
  font-family: Mulish;
  font-size: 16px;
  border: 1px solid lightgray;
  margin-bottom: 16px;
  letter-spacing: 0;
}

.building-shapes-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.building-shape-item {
  flex: 1 1 calc(50% - 8px);
  text-align: center;
  padding: 4px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #f9f9f9;
}

#map {
  height: 100vh;
}

</style>